import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
//import logo from "../images/logo.png"

const Nav = ({ siteTitle }) => (
  <header>
    <div className="nav">
        <ul>
            <li><Link to="/">HOME</Link></li>
            {/*<i className="fas fa-anchor"></i>*/}
            {/*<<li><img className="logo" src={logo} alt="logo" /></li>*/}
            <li><Link to="/media">MEDIA</Link></li>
            <li><Link to="/merch">MERCH</Link></li>
        </ul>
      </div>
  </header>
)

Nav.propTypes = {
  siteTitle: PropTypes.string,
}

Nav.defaultProps = {
  siteTitle: ``,
}

export default Nav