import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import Nav from "./nav"
import "./layout.css"
import babo from "../images/babo.gif"

const Layout = ({ children }) => {

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="NEW ALBUM MODEL CITIZENS OUT NOW" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
        <link href="https://fonts.googleapis.com/css?family=Oswald|Righteous&display=swap" rel="stylesheet" />
        <script defer src="https://use.fontawesome.com/releases/v5.1.0/js/all.js"></script>
        <title>DROP DIVER</title>
      </Helmet>
      <div className="App">
        <Nav/>
        <main>{children}</main>
        <footer className="footer">
            <div className="socials">
                <a href="https://open.spotify.com/artist/6u2OjiKcH2rvXfszl4gVyj?si=ifGP_7hPSc2OAsGVhUeWaA"><i className="fab fa-spotify fa-fw"></i></a>
                <a href="https://dropdiver.bandcamp.com/"><i className="fab fa-bandcamp fa-fw"></i></a>
                <a href="https://www.facebook.com/DropDiver/"><i className="fab fa-facebook-square fa-fw"></i></a>
                <a href="https://www.instagram.com/Dropdiverr/"><i className="fab fa-instagram fa-fw"></i></a>
                <a href="https://www.youtube.com/channel/UCvbq7ce7nGgA3bwRgk0aKiQ"><i className="fab fa-youtube fa-fw"></i></a>
            </div>
            <div className="copyright">DROP DIVER © 2022</div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
